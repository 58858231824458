import {Controller} from "@hotwired/stimulus"
import Select2 from "select2";

export default class extends Controller {
    connect() {
    }

    choose(event) {
        Select2();
        $('.search-by-symptom-select').val('').trigger('change');

        $(".main-symptom-button").removeClass("active");
        $(".main-symptom-button").addClass("inactive");

        $(this.element).removeClass("inactive");
        $(this.element).addClass("active");

        if ($("#submit-body-part-button").hasClass("info-inactive-button")) {
            $("#submit-body-part-button").removeClass("info-inactive-button");
            $("#submit-body-part-button").addClass("info-active-button");
        }

        $("#submit-body-part-button").attr("href", event.params.url)
    }
}