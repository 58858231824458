import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        $('#body-parts-vertical-scrollable').bind('DOMSubtreeModified', function (e) {
            if (e.target.innerHTML.length > 0) {
                var targetScrollToBodyPartId = $("#target-scroll-to-body-part-id");
                const bodyPartElement = document.getElementById(targetScrollToBodyPartId.val());
                if (targetScrollToBodyPartId.val() != undefined) {
                    if (bodyPartElement !== null) {
                        bodyPartElement.scrollIntoView();
                    }
                }
            }
        });
    }

    selected(event) {
        $.each($(".body-part-icon"), function (key, value) {
            if($(value).hasClass("active")){
                $(value).removeClass("active");
                $(value).addClass("inactive");
            }
        });

        $("#"+event.params.id).removeClass("inactive");
        $("#"+event.params.id).addClass("active");
    }

    up() {
        var currentPosition = parseInt($("#body-parts-vertical-container").scrollTop());
        currentPosition -= 50;

        $("#body-parts-vertical-container").animate({
            scrollTop: currentPosition
        }, 100);
    }

    down() {
        var currentPosition = parseInt($("#body-parts-vertical-container").scrollTop());
        currentPosition += 50;

        $("#body-parts-vertical-container").animate({
            scrollTop: currentPosition
        }, 100);
    }


}