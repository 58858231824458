import {Controller} from "@hotwired/stimulus"
import Select2 from "select2";

export default class extends Controller {
    connect() {
        Select2();
        $('.symptom_data_item_dropdown').select2({
            placeholder: "กรุณาเลือกคำตอบ",
            minimumResultsForSearch: -1,
            width: '100%'
        });
    }

    submitted() {
        var form = document.querySelector('#main-symptom-data-form');
        $(form).attr('novalidate', 'novalidate');
        form.classList.add('was-validated');
        if (form.checkValidity()) {
            form.submit();
        }
    }
}