import {Controller} from "@hotwired/stimulus"
import "bootstrap-datepicker";
import "bootstrap-datepicker/dist/locales/bootstrap-datepicker.th.min.js";

import moment from "daterangepicker/moment.min.js";
import "daterangepicker/daterangepicker.js";
import Select2 from "select2";

export default class extends Controller {
    static targets = ["acute", "chronic"]

    connect() {
        var self = this;
        $(this.acuteTarget).hide();
        $(this.chronicTarget).hide();

        var calendarStartDate = $("#calendar_start_date").val();

        var localTH = {
            "format": "DD/MM/YYYY",
            "separator": " - ",
            "applyLabel": "Apply",
            "cancelLabel": "Cancel",
            "fromLabel": "From",
            "toLabel": "To",
            "customRangeLabel": "Custom",
            "daysOfWeek": ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส"],
            "monthNames": ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"],
            "firstDay": 1
        }

        $('input[name="acute_calendar"]').daterangepicker({
            locale: localTH,
            singleDatePicker: true,
            autoUpdateInput: false,
            showDropdowns: true,
            maxDate: moment(),
            minDate: moment().subtract(calendarStartDate, "days")
        }, function (start, end, label) {
            $('input[name="acute_calendar"]').val(start.format('DD/MM/YYYY'));
            var dayDiff = moment().diff(start, 'days');
            $("#text-calculated-days").text(dayDiff);

            self.showCustomValidationInputMessage();
        });

        Select2();
        var durationUnit = $('#duration_unit').val();
        
        console.log("Duration Unit: " + durationUnit);
        $('#duration-select-input').select2({
            placeholder: "ระบุจำนวน" + durationUnit,
            minimumResultsForSearch: -1,
            width: '100%'
        }).on('select2:select', function (e) {
            self.showCustomValidationInputMessage();
        });;

        if($("#selected_severity").val() == "chronic"){
            this.chooseChronic();
        }
        else if($("#selected_severity").val() == "acute"){
            this.chooseAcute();
            $("#text-calculated-days").text($("#calendar_days_diff").val());
        }
    }

    chooseChronic() {
        $(this.acuteTarget).hide();
        $("#duration-calendar-input").removeAttr('required');

        $(this.chronicTarget).show();
        $("#duration-select-input").attr("required", true);

        this.showCustomValidationMessage();
        this.showCustomValidationInputMessage();
    }

    chooseAcute() {
        $(this.acuteTarget).show();
        $("#duration-calendar-input").attr("required", true);

        $(this.chronicTarget).hide();
        $("#duration-select-input").removeAttr('required');

        this.showCustomValidationMessage();
        this.showCustomValidationInputMessage();
    }

    submitted() {
        var form = document.querySelector('#main-symptom-group-form');

        if (form.checkValidity()) {
            form.submit();
        }
        else {
            this.showCustomValidationMessage();
            this.showCustomValidationInputMessage();
        }
    }

    showCustomValidationMessage() {
        if (!$('#flexRadioDefault1').is(':checked') && !$('#flexRadioDefault2').is(':checked')) {
            $("#with-severity-radio-validation-text").show();
        }
        else {
            $("#with-severity-radio-validation-text").hide();
        }
    }

    showCustomValidationInputMessage() {
        if ($('#flexRadioDefault1').is(':checked') ) {
            if ($('#duration-select-input').val() == null) {
                $("#with-severity-input-validation-text").show();
            }
            else {
                $("#with-severity-input-validation-text").hide();
            }
        }

        if ($('#flexRadioDefault2').is(':checked')) {
            if ($('#duration-calendar-input').val() == '') {
                $("#with-severity-input-validation-text").show();
            }
            else {
                $("#with-severity-input-validation-text").hide();
            }
        }
    }
}