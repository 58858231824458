// Entry point for the build script in your package.json
import "./src/jquery"
import "jquery-ujs"
import "@hotwired/turbo-rails"
import * as bootstrap from "bootstrap"
import "./controllers"


$(function () {
    console.log("Jquery is working");
})