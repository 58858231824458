import {Controller} from "@hotwired/stimulus"
import Select2 from "select2";

export default class extends Controller {
    connect() {
        $(this.element).modal('show');
    }

    choose(event) {
    }
}