import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        var positiveHeaderStatus = $(".positive-header")[0].className.split(" ")[1];
        var negativeHeaderStatus = $(".negative-header")[0].className.split(" ")[1];
        var otherHeaderStatus = $(".other-header")[0].className.split(" ")[1];
        $("#positive_header_status").val(positiveHeaderStatus);
        $("#negative_header_status").val(negativeHeaderStatus);
        $("#other_header_status").val(otherHeaderStatus);
    }
}