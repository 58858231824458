import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        jQuery(function ($) {
            $('#terms-and-conditions-scrollable').on('scroll', function () {
                const scrollMargin = 5;

                if ($(this).scrollTop() + $(this).innerHeight() >= ($(this)[0].scrollHeight) - scrollMargin) {
                    $("#accept-terms-and-conditions-panel").removeClass("disabledElement");
                    $("#accept-terms-and-conditions-panel").addClass("checkbox-group-active");
                }
            })
        });
    }

    accepted() {
        const isChecked = $('#terms-and-conditions-checkbox').is(":checked");
        if (isChecked) {
            $("#start-diagnosis-button").removeClass("info-inactive-button");
            $("#start-diagnosis-button").addClass("info-active-button");
        } else {
            $("#start-diagnosis-button").removeClass("info-active-button");
            $("#start-diagnosis-button").addClass("info-inactive-button");
        }
    }
}