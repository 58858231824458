import {Controller} from "@hotwired/stimulus"
import Select2 from "select2";

export default class extends Controller {
    connect() {
        $(this.element).modal('show');

        Select2();
        $('#symptom_data_item_modal').select2({
            placeholder: "กรุณาเลือก",
            minimumResultsForSearch: -1,
            width: '100%'
        });
    }

    choose(event) {
    }
}