import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
    }

    scrolldown() {
        $('html, body').animate({
            scrollTop: $("#benefits-section").offset().top
        }, 2000);
    }

}