import {Controller} from "@hotwired/stimulus"
import Select2 from "select2";

export default class extends Controller {
    connect() {
        console.log("New user is started started");
        

        var el = '.custom-select2';
        if ($(el).data('select2') == undefined && $(el).next().hasClass('select2-container')) {
            $(el).next().remove();
        }
        Select2();

        var $select = $('.custom-select2').select2();
        $select.each(function (i, item) {
            $(item).select2("destroy");
        });

        //Initialize select2
        Select2();
        $('.medical-conditions-select').select2({
            placeholder: "เลือกโรคประจำตัว"
        });

        $('.medicines-select').select2({
            placeholder: "เลือกยาที่ใช้อยู่"
        });

        $('#blood-type-select').select2({
            placeholder: "ระบุกรุ๊ปเลือด",
            minimumResultsForSearch: -1,
            width: '100%'
        });

        // Bind an event
        $('.custom-select2').on('select2:close', function (e) {
            var currentSelect2 = this;
            setTimeout(function () {
                $(currentSelect2).select2('close');
                $('.select2-container-active').removeClass('select2-container-active');
                $(':focus').blur();
            }, 1);
        });

        // Bind on select event
        $('.custom-select2').on('select2:select', function (e) {
            $(".select2-selection__choice__remove").attr({
                alt: "",
                title: ""
            });
        });
    }

    submitted() {
        console.log("form submitted button is clicked");
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var form = document.querySelector('#new-user-form');
        form.classList.add('was-validated');

        if (form.checkValidity()) {
            form.submit();
        }
    }
}