import {Controller} from "@hotwired/stimulus"
import Select2 from "select2";
import * as bootstrap from "bootstrap";

export default class extends Controller {
    connect() {
        console.log("Initialize select2");

        var el = '.search-by-symptom-select';
        if ($(el).data('select2') == undefined && $(el).next().hasClass('select2-container')) {
            $(el).next().remove();
        }
        Select2();

        var $select = $('.search-by-symptom-select').select2();
        $select.each(function (i, item) {
            $(item).select2("destroy");
        });

        $('.search-by-symptom-select').select2({
            width: '100%',
            placeholder: "ค้นหาอาการหลัก",
            sorter: function (data) {
                return data.sort(function (a, b) {
                    return a.text.trim() < b.text.trim() ? -1 : a.text.trim() > b.text.trim() ? 1 : 0;
                });
            }
        }).on('select2:select', function (e) {
            $('.select2-selection__rendered li.select2-selection__choice').sort(function (a, b) {
                return $(a).text().trim() < $(b).text().trim() ? -1 : $(a).text().trim() > $(b).text().trim() ? 1 : 0;
            }).prependTo('.select2-selection__rendered');

            $('.search-by-symptom-select').val('').trigger('change');
            var data = e.params.data;
            var redirectUrl = $(data["element"]).attr("id");
            window.location = redirectUrl;
        }).on('select2:close', function (e) {
            var currentSelect2 = this;
            setTimeout(function () {
                $(currentSelect2).select2('close');
                $('.select2-container-active').removeClass('select2-container-active');
                $(':focus').blur();
            }, 1);
        });

        $(".select2-selection__choice__remove").attr({
            alt: "",
            title: ""
        });
        //End Initialize select2


        //Initialize bodyparts
        // Handling body part's tooltip
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl)
        });

        function resetTooltip() {
            $(".bs-tooltip-end").hide();
            var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
            var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
                return new bootstrap.Tooltip(tooltipTriggerEl)
            });
        }

        $(document).ready(function () {
            var observer = new MutationObserver(function (e) {
                resetTooltip();
            });
            var bodyPartsVerticalScrollable = $('#body-parts-vertical-scrollable');
            observer.observe($(bodyPartsVerticalScrollable)[0], {childList: true});
        });
    }

}