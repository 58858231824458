import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
    }

    toggle() {
        if ($(this.element).hasClass("active")) {
            $(this.element).removeClass("active");
            $(this.element).addClass("inactive");
            $(this.element).next(".symptom-data-body").slideUp();
        } else if ($(this.element).hasClass("inactive")) {
            $(this.element).removeClass("inactive");
            $(this.element).addClass("active");
            $(this.element).next(".symptom-data-body").slideDown();
        }
    }
}