import {Controller} from "@hotwired/stimulus"
import ProgressBar from "progressbar.js";

export default class extends Controller {
    connect() {
        var userDiseaseId = this.data.get("id");
        var alarm = this.data.get("alarm");
        var severity = this.data.get("severity");
        var progressColor = this.getColorCode(parseFloat(severity)*100);

        var container = "#progress_bar_" + userDiseaseId;
        var circle = new ProgressBar.Circle(container, {
            color: progressColor,
            trailColor: '#E3F1FF',
            strokeWidth: 8,
            trailWidth: 8,
            text: {
                value: ''
            }
        });

        circle.animate(severity);
    }

    getColorCode(percentage) {
        var colorCode = '#308FFF';

        if (percentage >= 0 && percentage <= 10) {
            colorCode = '#40f4f7';
        } else if (percentage >= 11 && percentage <= 20) {
            colorCode = '#37eaed';
        } else if (percentage >= 21 && percentage <= 30) {
            colorCode = '#27dee1';
        } else if (percentage >= 31 && percentage <= 40) {
            colorCode = '#5ab8f5';
        } else if (percentage >= 41 && percentage <= 50) {
            colorCode = '#5db4ed';
        } else if (percentage >= 51 && percentage <= 60) {
            colorCode = '#4aa3de';
        } else if (percentage >= 61 && percentage <= 70) {
            colorCode = '#3995d2';
        } else if (percentage >= 71 && percentage <= 80) {
            colorCode = '#2a87c4';
        } else if (percentage >= 81 && percentage <= 90) {
            colorCode = '#1d7ab7';
        } else if (percentage >= 91 && percentage <= 100) {
            colorCode = '#1875b1';
        } else {
            console.log("Invalid color code");
            colorCode = '#308FFF';
        }

        return colorCode;
    }
}