import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        $(window).on('load', function () {
            var targetScrollToButtonId = $("#target-scroll-to-button-id");
            const element = document.getElementById(targetScrollToButtonId.val());
            if(element !== null){
                element.scrollIntoView();
            }

            var targetScrollToBodyPartId = $("#target-scroll-to-body-part-id");
            const bodyPartElement = document.getElementById(targetScrollToBodyPartId.val());
            if(bodyPartElement !== null){
                bodyPartElement.scrollIntoView();
            }
        });
    }
}